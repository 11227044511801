import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  Smartphone as SmartphoneIcon,
  Computer as ComputerIcon,
  ArrowForward as ArrowForwardIcon,
  Speed as SpeedIcon,
  Euro as EuroIcon,
  Route as RouteIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import flowerImage from "../assets/kukat/kuvat-mobiili.png";

const Flowers = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to detect iOS
  const isIOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const handleMobileRedirect = () => {
    const iosAppUrl = "https://apps.apple.com/us/app/id1502213567";
    const androidAppUrl =
      "https://play.google.com/store/apps/details?id=com.miwotec.xline";
    window.location.href = isIOS() ? iosAppUrl : androidAppUrl;
  };

  const handleWebRedirect = () => {
    window.location.href = "https://app.myxline.fi/asiakassivu";
  };

  const features = [
    {
      icon: <SpeedIcon color="primary" />,
      title: "Nopea ja tehokas",
      description: "Hetkessä jopa 100 lähetystä",
    },
    {
      icon: <EuroIcon color="primary" />,
      title: "Edulliset hinnat",
      description: "6,50€ (3-9 lähetystä) / 5,50€ (yli 9 lähetystä)",
    },
    {
      icon: <RouteIcon color="primary" />,
      title: "Älykäs reititys",
      description: "Optimoidut reitit kuljettajille",
    },
    {
      icon: <SettingsIcon color="primary" />,
      title: "Mukautuva sovellus",
      description: "Muokkaamme sovelluksesta juuri sinun tarvitsemasi",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        py: { xs: 3, sm: 4, md: 6 },
        px: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        component="h1"
        align="center"
        gutterBottom
        color="primary"
        sx={{ mb: { xs: 1.5, sm: 2 } }}
      >
        MyXline kukkalähetykset
      </Typography>

      <Typography
        variant={isMobile ? "h6" : "h5"}
        align="center"
        color="text.secondary"
        sx={{ mb: { xs: 3, sm: 4 } }}
      >
        Voit luoda lähetyksiä mobiili- tai verkkosovelluksella
      </Typography>

      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 4,
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={flowerImage}
          alt="Flower Delivery Service"
          sx={{
            width: '100%',
            height: 'auto',
            maxWidth: '600px',
            borderRadius: 2,
            boxShadow: 2,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
        />
      </Box>

      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 4,
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          size="large"
          endIcon={<ArrowForwardIcon />}
          onClick={handleMobileRedirect}
          sx={{
            py: 1.5,
            px: 4,
            textTransform: "none",
            fontWeight: 500,
            maxWidth: '400px',
            width: '100%',
          }}
        >
          Avaa mobiilisovellus
        </Button>
      </Box>
      {/* Features Grid */}
      <Grid container spacing={3} sx={{ mb: { xs: 4, sm: 6 } }}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                p: 2.5,
                backgroundColor: "background.paper",
                borderRadius: 1,
                boxShadow: 1,
                height: "100%",
                transition:
                  "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: 2,
                },
              }}
            >
              <Box
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  bgcolor: "primary.light",
                  display: "flex",
                  flexShrink: 0,
                }}
              >
                {feature.icon}
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                  {feature.title}
                </Typography>
                <Typography color="text.secondary">
                  {feature.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 4 }} />

      <Box sx={{ mb: 4, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom>
          {t("busService.watchVideo", "Watch How It Works")}
        </Typography>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 aspect ratio */,
            height: 0,
            overflow: "hidden",
            "& iframe": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            },
          }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/6nOKMsGP0G4"
            title="MyXline Flower Delivery Service"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          />
        </Box>
      </Box>
      {/* Apps Grid */}

      <Grid container spacing={3} sx={{ mb: 4 }}>
        {/* Mobile Card */}
        <Grid item xs={12} md={6}>
          <Card
            elevation={2}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: theme.shadows[4],
              },
            }}
          >
            <CardContent
              sx={{
                flexGrow: 1,
                p: { xs: 2.5, sm: 3 },
                "&:last-child": { pb: { xs: 2.5, sm: 3 } },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2.5,
                }}
              >
                <SmartphoneIcon
                  sx={{
                    mr: 1.5,
                    color: "primary.main",
                    fontSize: "2rem",
                  }}
                />
                <Typography variant="h5" component="h2">
                  Mobiilisovellus
                </Typography>
              </Box>
              <Typography
                sx={{ mb: 3, minHeight: "48px" }}
                color="text.secondary"
              >
                Saatavilla sekä iOS- että Android-laitteille
              </Typography>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={handleMobileRedirect}
                fullWidth
                sx={{
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Avaa mobiilisovellus
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Web Card */}
        <Grid item xs={12} md={6}>
          <Card
            elevation={2}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: theme.shadows[4],
              },
            }}
          >
            <CardContent
              sx={{
                flexGrow: 1,
                p: { xs: 2.5, sm: 3 },
                "&:last-child": { pb: { xs: 2.5, sm: 3 } },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2.5,
                }}
              >
                <ComputerIcon
                  sx={{
                    mr: 1.5,
                    color: "primary.main",
                    fontSize: "2rem",
                  }}
                />
                <Typography variant="h5" component="h2">
                  Verkkosovellus
                </Typography>
              </Box>
              <Typography
                sx={{ mb: 3, minHeight: "48px" }}
                color="text.secondary"
              >
                Käytä verkkosovellustamme luodaksesi ja hallitaksesi
                kukkalähetyksiä tietokoneeltasi.
              </Typography>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={handleWebRedirect}
                fullWidth
                sx={{
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Avaa verkkosovellus
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Flowers;
