import React from 'react';
import { 
  Alert, 
  AlertTitle, 
  IconButton, 
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Modal,
  Box,
  Paper
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { t } from 'i18next';

const ValidationDisplay = ({ errors, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!errors || errors.length === 0) return null;

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="validation-modal-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Paper
        elevation={24}
        sx={{
          width: isMobile ? '100%' : 'auto',
          maxWidth: '450px',
          maxHeight: '90vh',
          margin: isMobile ? 0 : 2,
          position: 'relative',
          outline: 'none',
        }}
      >
        <Alert
          severity="error"
          sx={{
            width: '100%',
            borderRadius: isMobile ? 0 : 1,
            '& .MuiAlert-message': {
              width: '100%'
            }
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
              sx={{ 
                p: 0.5,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <AlertTitle 
            id="validation-modal-title"
            sx={{ 
              fontWeight: 'bold',
              fontSize: isMobile ? '1rem' : '1.1rem',
              marginBottom: 1,
              paddingRight: '24px'
            }}
          >
            {t('errors.validationErrors', 'Required Fields Missing')}
          </AlertTitle>
          <List 
            dense 
            sx={{ 
              mt: 0.5, 
              mb: 0,
              maxHeight: '60vh',
              overflowY: 'auto',
              '& .MuiListItem-root': {
                paddingLeft: 0
              }
            }}
          >
            {errors.map((error, index) => (
              <ListItem key={index} sx={{ py: 0.5 }}>
                <ListItemText 
                  primary={error}
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: 'error.dark',
                    fontSize: isMobile ? '0.9rem' : '0.95rem'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Alert>
      </Paper>
    </Modal>
  );
};

// Validation utility function remains the same
export const validateFields = (fields) => {
  const { senderName, senderPhone, receiverName, receiverPhone, senderEmail, from, to } = fields;
  const errors = [];

  if (!senderName || senderName.length < 4) {
    errors.push(t('errors.senderName'));
  }

  if (!senderPhone || senderPhone.length < 7) {
    errors.push(t('errors.senderPhone'));
  }

  if (!receiverName || receiverName.length < 4) {
    errors.push(t('errors.receiverName'));
  }

  if (!receiverPhone || receiverPhone.length < 7) {
    errors.push(t('errors.receiverPhone'));
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!senderEmail || !emailRegex.test(senderEmail)) {
    errors.push(t('errors.senderEmail'));
  }

  if (!from || from.length < 5) {
    errors.push(t('errors.fromAddress'));
  }

  if (!to || to.length < 5) {
    errors.push(t('errors.toAddress'));
  }

  return errors;
};

export default ValidationDisplay;