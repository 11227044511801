import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid2,
} from "@mui/material";
import GoogleMap from "./GoogleMap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../App"; // Import Firebase auth
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const DeliveryConfirmationScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [delivery, setDelivery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [yNumber, setYNumber] = useState("");
  const [invoiceType, setInvoiceType] = useState("e-invoice");
  const localDelivery = useSelector((state) => state.delivery.delivery);

  const db = getFirestore();
  useEffect(() => {
    if (id) {
      setDelivery(localDelivery);
      setLoading(false);
    }
  });

  useEffect(() => {
    const fetchDelivery = async (userId) => {
      try {
        const userDoc = doc(db, "users", userId);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const activeDelivery = userData.my_active_deliveries?.[0];
          setDelivery(activeDelivery || localDelivery);
        } else {
          console.log("No user data found.");
          setDelivery(localDelivery);
        }
      } catch (error) {
        setDelivery(localDelivery);

        console.error("Error fetching delivery data:", error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!id) {
        if (user) {
          fetchDelivery(user.uid);
        } else {
          setLoading(false);
          navigate("/");
        }
      }
    });
    return () => unsubscribe();
  }, [db, navigate]);

  const handleInvoiceSubmission = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, {
          yNumber,
          companyInfo: {
            yNumber,
            paymentPreference: invoiceType,
            referenceNumber,
          },
        });
      } else {
        alert(t("notAuthenticated"));
      }
    } catch (error) {
      console.error("Error saving payment details:", error);
      alert(t("errorSubmittingDetails"));
    }
  };

  const {
    from,
    to,
    targetTime,
    distanceInfo,
    deliveryTransportType,
    paymentUrl,
    paymentMethod,
    coordinates,
  } = delivery || {};

  return (
    <Box height="100%">
      <Grid2 container>
        {/* Left Side - Details */}
        <Grid2 item size={{ xs: 12, md: 6 }} sx={{ p: "1%", pb: 0 }}>
          <StyledContainer>
            <Typography variant="h4" gutterBottom>
              {t("deliveryCreated")}
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              {t("waitingForCourier")}
            </Typography>

            {loading ? (
              <Typography variant="body1" color="textSecondary">
                {t("loading")}
              </Typography>
            ) : (
              <Box mt={2} textAlign="left" width="100%">
                {from && (
                  <Box sx={{ mt: 4 }}>
                    {/* Summary Box */}
                    <Box
                      sx={{
                        backgroundColor: "#f8f9fa",
                        borderRadius: "12px",
                        border: "1px solid #eee",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                        mb: 4,
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: "1px solid #eee",
                          p: 2,
                          backgroundColor: "#fff",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ color: "#333", fontWeight: 600 }}
                        >
                          {t("deliveryDetails")}
                        </Typography>
                      </Box>

                      <Box sx={{ p: 2 }}>
                        {/* Delivery Info Grid */}
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "130px 1fr",
                            gap: 2,
                            "& .label": {
                              color: "#666",
                              fontWeight: 500,
                            },
                            "& .value": {
                              color: "#333",
                              fontWeight: 400,
                            },
                          }}
                        >
                          <Typography className="label">
                            {t("pickupPlaceholder")}:
                          </Typography>
                          <Typography className="value">{from}</Typography>

                          <Typography className="label">
                            {t("dropoffPlaceholder")}:
                          </Typography>
                          <Typography className="value">{to}</Typography>

                          <Typography className="label">
                            {t("deliveredBy")}:
                          </Typography>
                          <Typography className="value">
                            {targetTime
                              ? new Date(targetTime).toLocaleString()
                              : t("notSpecified")}
                          </Typography>

                          {distanceInfo && (
                            <>
                              <Typography className="label">
                                {t("pricingPage.distance")}:
                              </Typography>
                              <Typography className="value">
                                {(distanceInfo.distance / 1000).toFixed(2)} km
                              </Typography>
                            </>
                          )}

                          <Typography className="label">
                            {t("deliveryDetailsScreen.paymentMethod")}:
                          </Typography>
                          <Typography className="value">
                            {paymentMethod
                              ? t(`deliveryDetailsScreen.${paymentMethod}`)
                              : t("notSpecified")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Payment Section */}
                    {paymentMethod === "card" && (
                      <Box
                        sx={{
                          mt: 3,
                          p: 3,
                          backgroundColor: "#f8f9fa",
                          borderRadius: "8px",
                          border: "1px solid #eee",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {t("paymentDetails")}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <a
                            href={paymentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#2563eb",
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {paymentUrl}
                          </a>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{ mt: 2, color: "#666" }}
                        >
                          {t("paymentConfirmationMessage")}
                        </Typography>
                      </Box>
                    )}

                    {/* Billing Section */}
                    {paymentMethod === "bill" && (
                      <Box
                        sx={{
                          mt: 3,
                          p: 3,
                          backgroundColor: "#f8f9fa",
                          borderRadius: "8px",
                          border: "1px solid #eee",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {t("paymentDetails")}
                        </Typography>
                        <TextField
                          fullWidth
                          label={t("reference")}
                          placeholder={t("referenceInfo")}
                          variant="outlined"
                          value={referenceNumber}
                          onChange={(e) => setReferenceNumber(e.target.value)}
                          sx={{ mt: 2 }}
                        />
                        <TextField
                          fullWidth
                          label={t("yNumber")}
                          variant="outlined"
                          value={yNumber}
                          onChange={(e) => setYNumber(e.target.value)}
                          sx={{ mt: 2 }}
                        />
                        <FormControl
                          component="fieldset"
                          sx={{ mt: 2, width: "100%" }}
                        >
                          <FormLabel component="legend">
                            {t("invoicePreference")}
                          </FormLabel>
                          <RadioGroup
                            row
                            value={invoiceType}
                            onChange={(e) => setInvoiceType(e.target.value)}
                          >
                            <FormControlLabel
                              value="e-invoice"
                              control={<Radio />}
                              label={t("eInvoice")}
                            />
                            <FormControlLabel
                              value="email-invoice"
                              control={<Radio />}
                              label={t("invoiceToEmail")}
                            />
                          </RadioGroup>
                        </FormControl>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={handleInvoiceSubmission}
                          sx={{ mt: 3 }}
                        >
                          {t("submitPaymentDetails")}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}

            <Button
              variant="contained"
              fullWidth
              onClick={() => navigate("/")}
              sx={{ mt: 4 }}
            >
              {t("newDelivery")}
            </Button>
          </StyledContainer>
        </Grid2>

        {/* Right Side - Map */}
        <Grid2
          item
          size={{ md: 6 }}
          sx={{
            mb: "16px",
            p: "1%",
            borderRadius: "16px",
            maxHeight: "630px", // Set your desired max height here
            overflow: "auto", // Add this to allow scrolling if the content exceeds the max height
          }}
        >
          {useMediaQuery(useTheme().breakpoints.up("md")) && (
            <GoogleMap coordinates={coordinates || {}} />
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default DeliveryConfirmationScreen;
