import React from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Container,
  Box,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import PublicIcon from "@mui/icons-material/Public";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import CodeIcon from "@mui/icons-material/Code";
import ComputerIcon from "@mui/icons-material/Computer";
import { t } from "i18next";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";

export default function HomePageSections() {
  // Function to determine which store link to display based on the user's browser
  const getStoreLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "https://apps.apple.com/us/app/id1502213567"; // App Store link
    } else {
      return "https://play.google.com/store/apps/details?id=com.miwotec.xline"; // Google Play link
    }
  };

  // Reusable ServiceCard component
  const ServiceCard = ({
    icon: Icon,
    title,
    description,
    buttonText,
    buttonLink,
    image,
  }) => (
    <Card
      style={{
        backgroundColor: "#f5f5f5",
        height: "100%",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <CardContent>
        {image ? (
          <img
            src={image}
            alt={title}
            style={{ width: "100px", marginBottom: "20px" }}
          />
        ) : (
          Icon && (
            <Icon
              style={{ fontSize: 50, color: "#1976d2", marginBottom: "20px" }}
            />
          )
        )}
        <Typography variant="h6">{title}</Typography>
        <Typography>{description}</Typography>
        <Button
          variant="contained"
          href={buttonLink}
          style={{ marginTop: "20px" }}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Container maxWidth="lg" sx={{ my: 6 }}>
        <Grid container spacing={2} justifyContent="center">
          {/* <Grid item xs={12} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
            <ServiceCard
              icon={LocalShippingIcon}
              title={t("frontPageSections.deliveryTypes")}
              description={t("frontPageSections.deliveryTypesDescription")}
              buttonText={t("frontPageSections.deliveryTypesButton")}
              buttonLink="/pricing"
            />
          </Grid> */}
          <Grid item xs={12} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
            <ServiceCard
              icon={DirectionsBusIcon}
              title={t("frontPageSections.busService")}
              description={t("frontPageSections.busServiceDescription")}
              buttonText={t("frontPageSections.busServiceButton")}
              buttonLink="/bussi"
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
            {" "}
            {/* Add margin bottom for mobile */}
            <ServiceCard
              icon={SettingsIcon}
              title={t("frontPageSections.deliveryCustomization")}
              description={t(
                "frontPageSections.deliveryCustomizationDescription"
              )}
              buttonText={t("frontPageSections.deliveryCustomizationButton")}
              buttonLink="/contact"
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: { xs: 0, md: 0 } }}>
            {" "}
            {/* Add margin bottom for mobile */}
            <ServiceCard
              icon={PublicIcon}
              title={t("frontPageSections.deliveryRange")}
              description={t("frontPageSections.deliveryRangeDescription")}
              buttonText={t("frontPageSections.deliveryRangeButton")}
              buttonLink="/contact"
            />
          </Grid>
        </Grid>
      </Container>

      {/* Become a Courier Section */}
      <Container maxWidth="lg" sx={{ my: 6 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" align="center" gutterBottom>
            {t("frontPageSections.becomeACourier")}
          </Typography>
          <Typography align="center">
            {t("frontPageSections.becomeACourierDescription")}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button variant="contained" href={getStoreLink()} target="_blank">
              {t("frontPageSections.downloadAppCourier")}
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Solutions for Businesses Section */}
      <Container maxWidth="lg" sx={{ my: 6 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" align="center" gutterBottom>
            {t("frontPageSections.businessSolutions")}
          </Typography>
          <Typography align="center">
            {t("frontPageSections.businessSolutionsDescription")}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              href="https://app.myxline.fi/asiakassivu"
              target="_blank"
            >
              {t("frontPageSections.createUserSender")}
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Download the App Section */}
      <Container maxWidth="lg" sx={{ my: 6 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
            <ServiceCard
              icon={SmartphoneIcon}
              title={t("frontPageSections.getTheAppForMobile")}
              description={t("frontPageSections.getTheAppForMobileDescription")}
              buttonText={t("frontPageSections.getTheAppForMobileButton")}
              buttonLink={getStoreLink()}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
            <ServiceCard
              icon={ComputerIcon}
              title={t("frontPageSections.getTheWebApp")}
              description={t("frontPageSections.getTheWebAppDescription")}
              buttonText={t("frontPageSections.createUserSender")}
              buttonLink={getStoreLink()}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
            <ServiceCard
              icon={CodeIcon}
              title={t("frontPageSections.getIntegration")}
              description={t("frontPageSections.getIntegrationDescription")}
              buttonText={t("frontPageSections.getIntegrationButton")}
              buttonLink="/integrations"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
