import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Code as CodeIcon,
  Shop as ShopIcon,
  ShoppingCart as ShopifyIcon,
  Api as ApiIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { useParams, useLocation } from "react-router-dom";
import recordPageView from "../utils/recordPageView";


const ApiPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const { id } = useParams();
  const location = useLocation(); // Initialize useLocation

  const [recoded, setRecorded] =useState(false)

  useEffect(() => {
    const deviceInfo = {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
    };

    const shouldSave = !recoded && id
    if (shouldSave) {
      recordPageView({
        deliveryId: id,
        userId: "unknown",
        deviceInfo,
        source: 'merchant'
      });
      setRecorded(true)
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log("Form submitted:", formData);
    setOpenDialog(false);
    setSnackbar({
      open: true,
      message: "Kiitos yhteydenotostasi! Palaamme sinulle pian.",
      severity: "success",
    });
    setFormData({ name: "", email: "", company: "", message: "" });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const integrations = [
    {
      title: "nShift",
      icon: <ApiIcon sx={{ fontSize: 40 }} />,
      description: "MyXline integroituu saumattomasti nShift-toimitusratkaisuun. Yhdistä lähetysten hallinta nShift-järjestelmääsi.",
      benefits: [
        "Automaattinen lähetysten synkronointi",
        "Reaaliaikainen seuranta",
        "Keskitetty hallinta",
      ],
    },
    {
      title: "WooCommerce",
      icon: <ShopIcon sx={{ fontSize: 40 }} />,
      description: "WooCommerce-verkkokaupan integraatio MyXlinen kanssa mahdollistaa saumattoman toimitusketjun.",
      benefits: [
        "Automaattiset toimitustiedot",
        "Tilausten automaattinen synkronointi",
        "Helppo asentaa ja käyttää",
      ],
    },
    {
      title: "Shopify",
      icon: <ShopifyIcon sx={{ fontSize: 40 }} />,
      description: "Shopify-kauppiaille räätälöity integraatio tehostaa verkkokaupan toimitusprosessia.",
      benefits: [
        "Automatisoitu tilausten käsittely",
        "Toimitushintojen laskenta",
        "Seurantakoodien päivitys",
      ],
    },
    {
      title: "REST API",
      icon: <CodeIcon sx={{ fontSize: 40 }} />,
      description: "Kattava REST API mahdollistaa räätälöidyt integraatiot omiin järjestelmiisi.",
      benefits: [
        "Dokumentoitu rajapinta",
        "Turvallinen autentikointi",
        "Joustavat integraatiomahdollisuudet",
      ],
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box textAlign="center" mb={6}>
        <Typography variant="h3" component="h1" gutterBottom color="primary">
          Integraatiot
        </Typography>
        <Typography variant="h6" color="text.secondary" mb={4}>
          MyXline tarjoaa monipuoliset integraatiomahdollisuudet eri alustoille
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => setOpenDialog(true)}
          startIcon={<SendIcon />}
          sx={{
            py: 1.5,
            px: 4,
            textTransform: "none",
            borderRadius: 2,
          }}
        >
          Kysy lisää integraatioista
        </Button>
      </Box>

      <Grid container spacing={4}>
        {integrations.map((integration, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    color: 'primary.main',
                  }}
                >
                  {integration.icon}
                  <Typography variant="h5" ml={2}>
                    {integration.title}
                  </Typography>
                </Box>
                <Typography variant="body1" paragraph>
                  {integration.description}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  {integration.benefits.map((benefit, idx) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        '&:before': {
                          content: '"•"',
                          color: 'primary.main',
                          fontWeight: 'bold',
                          mr: 1,
                        },
                      }}
                    >
                      {benefit}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Contact Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Kysy lisää integraatioista</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Nimi"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Sähköposti"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Yritys"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Viesti"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={() => setOpenDialog(false)}>Peruuta</Button>
            <Button
              type="submit"
              variant="contained"
              startIcon={<SendIcon />}
            >
              Lähetä
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ApiPage;